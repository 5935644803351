import qs from 'qs'
import axios from 'axios'
import store from "../store/modules/Users"
import router from '@/router'

class Http {
  
  defaultApi = process.env.VUE_APP_DEFAULT_API;
  defaultInstance;
  
  constructor() {    
    let _defaultInstance = axios.create({ baseURL: this.defaultApi});
    this.addInterceptors(_defaultInstance);
    this.defaultInstance = _defaultInstance;
  }
  
  newInstance(_url){
    let _instance = axios.create({ baseURL: _url});
    // this.addInterceptors(_instance);
    return _instance
  }
  
  async get(path, instance = this.defaultInstance) {
    let response = await instance.request({
                      method: 'GET',
                      url: path,
                      responseType: 'json',
                      // data:  qs.stringify(payload)
                    }).catch( error  => { return error.request.response })
    return response
  }

  async post(path, payload, instance = this.defaultInstance) {
    
    let response =  await instance.request({
                      method: 'POST',
                      url: path,
                      responseType: 'json',
                      data:  qs.stringify(payload)
                    }).catch( error  => {
                      return error.request.response
                    })
                    
    return response
  }

  async delete(path, payload, instance = this.defaultInstance) {
    // let response =  await instance.delete(path).catch( error  => { return error })
    let response = await instance.request({
                    method: 'DELETE',
                    url: path,
                    responseType: 'json',
                    data:  qs.stringify(payload)
                  }).catch( error  => { return error.request.response })
    return response
  }

  async update(path, payload, instance = this.defaultInstance) {
    let response =  await instance.request({
                      method: 'PUT',
                      url: path,
                      responseType: 'json',
                      data:  qs.stringify(payload)
                    }).catch( error  => { return error })
    
    return response
  
  }

  downloadFiles(path, payload, instance = this.defaultInstance) {
    return instance.request({
      method: 'POST',
      url: path,
      responseType: 'blob',
      data:  qs.stringify(payload)
    })
  }


  addInterceptors(instance){
    
    instance.interceptors.request.use((config) => {
      config.headers.common['secret']         = 'CreatiAppSystem'
      config.headers.common['x-access-token'] = store.state.token
      config.headers.common['authorization']  = store.state.token
      return config
    })

    instance.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      // Do something with response error
      if(error.response.status == 403 || error.response.status ===  401){
        //store.dispatch('LOG_OUT');
        store.state.user = null
        localStorage.removeItem('RSTKN')
        localStorage.removeItem('RSUSR')
        router.push('/login');
      }
      return Promise.reject(error);
    });
  }



}


export default new Http
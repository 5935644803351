import UserService from "../../services/UserService";

'use-strict'
export  default{
    namespaced: true,
    state: {
        users: [],
        status: '',
        token:  localStorage.getItem('RSTKN') || '',
        user :  JSON.parse(localStorage.getItem('RSUSR')) || null,
        company : JSON.parse(localStorage.getItem('company')) || {},
    },
    mutations: {
        AUTH_SUCCESS(state, token, user){
            state.status = 'success'
            state.token = token
            state.user = user
        },
        AUTH_ERROR(state){
            state.status = 'error'
        },
        CURRENT_USER(state, val){
            state.user = Object.assign({}, val)
        },
        SET_COMPANY (state, val){
            state.company = val
        },
        SET_USERS (state, value){
            state.users = value
        },
        ADD_USER (state, val){
            state.users.push(val)
        },
        LOG_OUT(state){
            state.status = ''
            state.token = ''
            state.user  = null 
        },
    },
    actions: {
        async LOGIN({commit}, user){
            let response =  await UserService.login(user);
            if( (response.status / 100) == 2 ){
                let token  = response.data.token
                let user   = response.data.user
                let roles  = response.data.info.roles
                let company  = response.data.company != null ? response.data.company : null
                if(company != null){
                   company.country = { code: company.cou01Code, name: company.countryName}
                   company.city    = { code: company.ci01Code, name: company.cityName} 
                   company.status  = { id: company.st01Id}
                }
                localStorage.setItem('RSTKN', token)
                localStorage.setItem('RSUSR', JSON.stringify(user))
                localStorage.setItem('a-c', JSON.stringify(roles))
                localStorage.setItem('company', JSON.stringify(company))
                commit('AUTH_SUCCESS', token, user)
                commit('CURRENT_USER', user)
                commit('SET_COMPANY', company)
                return true
            } else {
                return false
            }
        },
        async UPDATE_PROFILE({commit}, user){
            let response =  await UserService.updateProfile(user);
            if( (response.status / 100) == 2 ){
                localStorage.setItem('RSUSR', JSON.stringify(user))
                commit('CURRENT_USER', user)
                return true
            } else {
                return false
            }
        },
        async UPDATE_COMPANY({commit}, company){
            let response =  await UserService.updateCompany(company);
            if( (response.status / 100) == 2 ){
                localStorage.setItem('company', JSON.stringify(company))
                commit('SET_COMPANY', company)
                return true
            } else {
                return false
            }
        },
        async CREATE_USER({commit}, user){
            let response =  await UserService.createUser(user);
            if( (response.status / 100) == 2 ){
                commit('ADD_USER', user)
                return true
            } else {
                return false
            }
        },
        async LOG_OUT ({commit}){
            // let response =  await UserService.logOut();
            // if( (response.status / 100) == 2 ){
                console.log('GOOD BYE')
                localStorage.removeItem('RSTKN')
                localStorage.removeItem('RSUSR')
                localStorage.removeItem('company')
                localStorage.removeItem('a-c')
                commit('LOG_OUT')
                return true
            // } else {
            //     return false
            // }
        },
        async FETCH_USERS ({commit}) {
            let response = await UserService.get()
            if( (response.status / 100) == 2 ){
                commit('SET_USERS', response.data)
                return true
            }
        },
        RESET_USER_DATA({commit}){
            let user = JSON.parse(localStorage.getItem('RSUSR')) || {}
            let company = JSON.parse(localStorage.getItem('company')) || {}
            commit('CURRENT_USER', user)
            commit('SET_COMPANY', company)
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        GET_CURRENT_USER: state => state.user,
        GET_COMPANY: state => state.company,
        GET_TOKEN:  state => state.token,
        GET_USERS: (state) => {  return state.users},
    }
}
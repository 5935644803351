import Http from "../helpers/Http";

class UserService {
  
    async get (){
        let response = await Http.get("/users")
        return response
    }

    async findUser(userId){
        let response = await Http.get("/users/"+userId)
        return response
    }

    async delete(data) {
        let response = await Http.post("/users/delete", data)
        return response  
    }

    async login(data) {
        return await Http.post("/auth/login",data);
    }

    async recovery(data){
        return await Http.post("/users/restPwd",data);
    }

    async logOut(){
        return await Http.update("/auth/logout",null);
    }
    

    async updateCompany(data){
        return await Http.update("/companies",data);
    }

    async updatePassword(data){
        return await Http.update("/users/changePass",data);
    }

    async create(data){
        return await Http.post("/users",data);
    }

    async updateUser(data){
        return await Http.update("/users",data);
    }

    async existEmail(data){
        return await Http.post("/users/exist", data)
    }
    
    async existCompanyCode(data){
        return await Http.post("/companies/exist", data)
    }
    
    async uploadImage(data){
        return await Http.post("/files/uploadProfile", data)
    }
    
    async getProfile(id){
        let response =  await Http.get("/users/profile/" + id)
        return response
    }

    async updateProfile(data){
        return await Http.post("/users/update",data);
    }
    
    

}

export default new UserService();